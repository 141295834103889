import React, { useState, useEffect } from "react";
import HomeHero from "./homeSec1/HomeHero";
import HomeHeroBottom from "./homeSec1/HomeHeroBottom";
import OurServices from "./homeSec2/OurServices";
import HomeAbout from "./homeSec4/HomeAbout";
import HomeOurTeam from "./homeSec5/HomeOurTeam";
import HomeFaq from "./homeSec6/HomeFaq";
import HomeConsultant from "./homeSec7/HomeConsultant";
import HomeSubscribe from "../UnusedComponents/homeSec8/HomeSubscribe";
import client from "../../sanityCli";
import LatestBlog from "./homeSec3.2/LatestBlog";
import Navbar from "../navbar/Navbar";
import Footer from "./homeSec9/Footer";
import CardsGames from "./homeSec3.1/CardsGames";
// import Games from "./homeSec3.1/Games";
// import RatingScale from "../Reviews/RatingScale";


export default function HomeComponents() {
  const [heroData, setHeroData] = useState(null);
  const [faqData, setFaqData] = useState(null);


  useEffect(() => {
    let passedValue = localStorage.getItem("myData");
    if (passedValue !== "true") {
      window.location.reload();
      localStorage.setItem("myData", "true");
    }
    const fetchData = async () => {
      try {
        const result = await client.fetch(`*[_type == "homeHero"][0]`);
        setHeroData(result);
        // console.log('result', result)

        const faqResult = await client.fetch(`*[_type == "faq"][0]`);
        setFaqData(faqResult);
        // console.log('faq result', faqResult);
      } catch (error) {
        console.error("Error fetching data from Sanity:", error);
      }
    };

    fetchData();
  }, []);



  return (
    <div className="overflow-hidden">
      <Navbar />
      {heroData && <HomeHero heroData={heroData} />}
      <HomeHeroBottom />
       <OurServices />
       <CardsGames />
       <LatestBlog />
      <HomeAbout />
      <HomeOurTeam />
      {faqData && <HomeFaq faqData={faqData} />}
      <HomeConsultant />
      {/* <HomeSubscribe /> */}
      <Footer />

    </div>
  );
}
