import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import imageUrlBuilder from "@sanity/image-url";
import client from "../../../sanityCli";

const builder = imageUrlBuilder(client);

function urlFor(source) {
  return builder.image(source);
}

export default function HomeAbout() {
  const [aboutData, setAboutData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await client.fetch('*[_type == "aboutUs"][0]');
        setAboutData(result);
      } catch (error) {
        console.error("Error fetching data from Sanity:", error);
      }
    };

    fetchData();
  }, []);

  if (!aboutData) return null;

  const {
    header,
    subHeader,
    description,
    rightHeader,
    rightDescription,
    aboutImg,
    stats,
    socialMediaIcon,
  } = aboutData;

  return (
    <div className="lg:py-10 lg:px-20 py-8 px-2 lg:mt-[6%]" id="about">
      <div className="flex flex-col items-center gap-4">
        <p className="text-yellow-500 uppercase text-[3vw] lg:text-2xl font-semibold">
          {header}
        </p>
        <h1 className="text-center font-bold text-[4.8vw] md:text-4xl lg:text-4xl 2xl:text-6xl text-gray-700 Montserrat uppercase tracking-wider">
          {subHeader}
        </h1>
        <h6 className="text-center font-normal text-sm px-[1vw] md:text-xl lg:text-3xl text-gray-700 Poppins leading-relaxed tracking-wide xl:px-56">
          {description}
        </h6>
      </div>
      <div className="w-full flex-col flex lg:flex-row items-center gap-8 lg:py-16">
        <div className="lg:w-2/5 w-full">
          {aboutImg && <img src={urlFor(aboutImg)} alt="/" />}
        </div>
        <div className="w-full flex flex-col items-center gap-4 lg:items-start lg:gap-6">
          <h1 className="text-3xl font-medium mt-4">{rightHeader}</h1>
          <p className="text-center lg:text-start text-slate-600 px-2 lg:px-0 lg:py-0 lg:text-xl w-[90vw] md:w-full">
            {rightDescription}
          </p>
          <div className="w-full flex items-center justify-center md:justify-start space-x-2 lg:space-x-10 ml-4 lg:ml-0 my-4">
            {stats.map((stat, index) => (
              <div
                key={index}
                className={`bg-yellow-50 shadow-lg lg:px-6 lg:py-2 py-2 px-4 flex flex-col items-start gap-1 border rounded-lg ${
                  index === 2 && window.innerWidth < 768 ? "hidden" : ""
                }`}
              >
                <p className="lg:text-2xl text-sm font-medium text-center">
                  {stat.value}
                </p>
                <p className="text-[8px] md:text-base text-start">
                  {stat.label}
                </p>
              </div>
            ))}
          </div>
          <div className="flex items-center justify-around lg:justify-start w-full lg:space-x-12 ">
            {socialMediaIcon.map((image, id) => (
              <Link key={id} to={image.iconUrl} target="_blank">
                <img
                  src={urlFor(image.icon)}
                  alt="/"
                  className=""
                />
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
