
import React, { useState, useEffect } from "react";
// import client from "../../../sanityCli";

export default function HomeFaq({ faqData }) {
  const [faqItems, setFaqItems] = useState([]);
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");

  useEffect(() => {
    if (faqData) {
      const { title, subtitle, faqItems } = faqData;
      setTitle(title || "");
      setSubtitle(subtitle || "");
      setFaqItems(faqItems || []);
    }
  }, [faqData]);

  const [expandedItems, setExpandedItems] = useState({});

  const toggleExpansion = (index) => {
    setExpandedItems((prevExpandedItems) => ({
      ...prevExpandedItems,
      [index]: !prevExpandedItems[index],
    }));
  };

  return (
    <div className="w-full py-[5%] mb-[8%] lg:mb-[5%] flex flex-col items-center justify-center space-y-8">
      <div className="flex flex-col items-center justify-center md:space-y-8 space-y-4">
        <h1 className="text-2xl  md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl font-bold leading-tight text-black text-center">
         {title}
        </h1>
        <p className="text-[12px] md:text-xl lg:text-2xl text-center font-normal leading-relaxed text-gray-700">
         {subtitle}
        </p>
      </div>
      <div className="w-full px-6 md:px-16">
        {faqItems.map((item, index) => (
          <div
            key={index}
            className={`w-full border-t border-gray-300 ${
              index === faqItems.length - 1 ? "border-b" : ""
            }`}
          >
            <div className="flex items-center justify-between gap-4 py-4 md:py-4">
              <p className="text-[12px] md:text-2xl md:font-normal font-normal leading-tight text-black">
                {item.question}
              </p>
              <span
                className={`bg-yellow-50 text-black flex items-center justify-center lg:w-16 lg:h-16 w-8 h-6 rounded-full cursor-pointer ${
                  expandedItems[index] ? "bg-yellow-200 text-gray-600" : ""
                }`}
                onClick={() => toggleExpansion(index)}
              >
                {expandedItems[index] ? <>&times;</> : <>&#43;</>}
              </span>
            </div>
            {expandedItems[index] && (
              <p className="pb-4 mt-2 md:mt-0 md:text-base font-medium text-[10px] f leading-relaxed text-gray-700 w-[90%]">
                {item.answer}
              </p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}




