import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../navbar/Navbar';
import Footer from '../Home/homeSec9/Footer';
import image from './image.svg'
import icon_1 from './Frame (2).svg';
import icon_2 from './Frame (3).svg';
import icon_3 from './Frame (4).svg';
import icon_4 from './Frame (5).svg';
import icon_5 from './icon-1.svg';

function Error() {
  return (
    <>
    <Navbar />

    <div className='w-full flex flex-col items-center justify-center space-y-8 my-20'>
         <img src = {image} alt='error' className='w-[50vw] '/>
         <p className='text-black font-semibold text-2xl md:text-4xl lg:text-5xl 2xl:text-7xl'>Oops!</p>
         <p className='text-gray-500 text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl font-normal'> We can’t seem to find the page you are looking for</p>
         <div className='flex items-center gap-12 py-8 h-24'>
            <button className='w-[15vw] hover:bg-yellow-500 hover:p-4 hover:rounded-xl hover:text-black text-2xl text-yellow-600'>Back To Home</button>
            <button className='w-[15vw] hover:bg-yellow-500 hover:p-4 hover:rounded-xl hover:text-black text-2xl text-yellow-600'>Read blogs</button>
         </div>
         <p className='text-gray-700 text-xl'>Follow Us On</p>
         <div className='flex items-center justify-center space-x-8'>
             <Link to='/'> <img src={icon_1} alt='/' className='bg-yellow-100 p-4 rounded-3xl w-[4vw]'/></Link>
              <img src={icon_2} alt='/' className='bg-yellow-100 p-4 rounded-3xl w-[4vw]'/>
              <img src={icon_3} alt='/' className='bg-yellow-100 p-4 rounded-3xl w-[4vw]'/>
              <img src={icon_4} alt='/' className='bg-yellow-100 p-4 rounded-3xl w-[4vw]'/>
              <img src={icon_5} alt='/' className='bg-yellow-100 p-4 rounded-3xl w-[4vw]'/>      
         </div>
    </div>
    <Footer />
    </>
  )
}

export default Error;