import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import client from "../../sanityCli";
import imageUrlBuilder from "@sanity/image-url";
import Footer from "../Home/homeSec9/Footer";
import Navbar from "../navbar/Navbar";
import HomeConsultant from "../Home/homeSec7/HomeConsultant";
import HomeSubscribe from "../UnusedComponents/homeSec8/HomeSubscribe";

const builder = imageUrlBuilder(client);

function urlFor(source) {
  return builder.image(source);
}

function ReviewPage() {
  const [reviewHeroData, setReviewHeroData] = useState(null);
  const [reviewCardData, setReviewCardData] = useState(null);
  const [filteredReviewsData, setFilteredReviewsData] = useState([])
  const [filterCriteria, setFilterCriteria] = useState(null);
  const [pageData, setPageData] = useState(null);
  const [startIndex, setStartIndex] = useState(0);
  const reviewsPerPage = 2;
  const reviewsPerPageLink = 5;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  useEffect(() => {
    // Fetch review hero data
    client
      .fetch(`*[_type == 'review'][0].reviewHeroData`)
      .then((data) => { setReviewHeroData(data);     
      setFilterCriteria(data.btnLatest.btnLatestText)
      })
   
      .catch((error) =>
        console.error("Error fetching review hero data:", error)
      );


      const fetchPageData = async () => {
        try {
          const result = await client.fetch('*[_type == "pageContent"][0]');
          setPageData(result);
          // console.log("pageNavData", result);
        } catch (error) {
          console.error("Error fetching data from Sanity:", error);
        }
      };
      fetchPageData();

    // Fetch review card data
    client
      .fetch(`*[_type == 'review'][0].reviewCardData`)
      .then((data) =>{
        setReviewCardData(data);
        if(!filterCriteria)
        setFilteredReviewsData(data.sort((a, b) => new Date(b.reviewTime) - new Date(a.reviewTime)));  
      } )
      .catch((error) =>
        console.error("Error fetching review card data:", error)
      );


  }, []);

  if (!reviewHeroData || !reviewCardData) {
    return <div></div>;
  }

  const filterReviewsByTitle = (title) => {
    setFilteredReviewsData(reviewCardData.filter((review) => review.filterText === title))
    setFilterCriteria(title);
    setStartIndex(0);
  };



  const filterReviewByTime = (reviews, title) => {
    const reviewsIST = reviews.map(review => {
      return review;
    });
    setFilteredReviewsData(reviewsIST.sort((a, b) => new Date(b.reviewTime) - new Date(a.reviewTime)));
    setFilterCriteria(title);
  
  }
  

  const isActive = (title) => {
    return title === filterCriteria;
  };

  const handleNextPage = () => {
    const nextIndex = startIndex + reviewsPerPage;
    setStartIndex(
      nextIndex >= (filteredReviewsData?.length || 0) ? startIndex : nextIndex
    );
  
    // Scroll to the review-box section
    const reviewBoxSection = document.getElementById('review-box');
    if (reviewBoxSection) {
      reviewBoxSection.scrollIntoView({ behavior: 'smooth' });
    }
  };
  
  const handlePrevPage = () => {
    const prevIndex = startIndex - reviewsPerPage;
    setStartIndex(prevIndex < 0 ? 0 : prevIndex);
  
    // Scroll to the review-box section
    const reviewBoxSection = document.getElementById('review-box');
    if (reviewBoxSection) {
      reviewBoxSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

   function convertDateToPrintable(date) {
   let dateArray = (new Date(date)).toString().split(' ');
   let printableDate = dateArray[0] + ' ' +  dateArray[1] + ' ' + dateArray[2]+ ' ' + dateArray[3] + ' ' + dateArray[4] ;
    return printableDate;
  }

  const getRandomColor = (colors) => {
    const randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex];
  };

  const customColors = ["#FF8484", "#2351F5", "#FF7C32", "#23A54F", "#9921C3"]; // Custom colors array

// Usage example
const randomColor = getRandomColor(customColors);




  return (
    <>
    <Navbar />
    <div className="reviews-container w-full p-6 lg:p-20">
      <div className="flex md:flex-col flex-col-reverse items-center gap-8">
        <div className="flex flex-col items-center">
          <h1 className="text-center font-montserrat text-2xl md:text-3xl lg:text-5xl xl:text-6xl font-bold uppercase tracking-widest">
            {reviewHeroData.title}
          </h1>
          <p className="text-center font-poppins text-lg lg:text-2xl xl:text-3xl xl:w-[70%] font-normal leading-relaxed tracking-wide lg:mt-8 mt-4">
            {reviewHeroData.subTitle}
          </p>
        </div>
        <img
          src={urlFor(reviewHeroData.heroImage)}
          alt="review-hero-image"
          className="md:h-[660px] h-72 w-full rounded-sm"
        />
      </div>
      <div id="review-box" className="top-reviews-container my-20">
        <h1 className="font-montserrat text-2xl md:text-4xl text-black lg:text-5xl 2xl:text-7xl font-semibold tracking-tight leading-normal uppercase text-center lg:text-start">
          Top Reviews
        </h1>
        <div className="filtered-list flex items-center space-x-5 mt-16">
          <button
             id="buttonLatest"
            className={`w-64 py-3 text-center uppercase relative text-base font-medium  ${
              isActive(reviewHeroData.btnLatest.btnLatestText)
                ? "text-[20px] font-semibold"
                : ""
            }`}
            onClick={() => filterReviewByTime(reviewCardData, reviewHeroData.btnLatest.btnLatestText)}
          >
            <p className="relative z-10 uppercase">
              {" "}
              {reviewHeroData.btnLatest.btnLatestText}
             {reviewHeroData.btnLatest.btnLatestIcon && <img className="w-5 absolute bottom-1 right-8 lg:right-16"  src={urlFor(reviewHeroData.btnLatest.btnLatestIcon)} />}
            </p>
            <span
              className={`absolute top-0 left-0 right-0 bottom-0 ${
                isActive(reviewHeroData.btnLatest.btnLatestText)
                  ? "text-gray-900 bg-gradient-to-r from-yellow-500 to-yellow-300 transform skew-x-[-20deg] z-0"
                  : "absolute top-0 left-0 right-0 bottom-0 border-[1px] border-yellow-500 transform skew-x-[-20deg] z-0"
              }`}
            ></span>
          </button>
          <button
            className={`w-64 py-3 text-center uppercase relative text-base font-medium ${
              isActive(reviewHeroData.btnEditor.btnEditorText)
                ? "text-[20px] font-semibold"
                : ""
            }`}
            onClick={() => filterReviewsByTitle(reviewHeroData.btnEditor.btnEditorText)}
          >
            <p className="relative z-10 uppercase text-sm">
              {reviewHeroData.btnEditor.btnEditorText}
              {reviewHeroData.btnEditor.btnEditorIcon && <img className="w-5 absolute top-0 lg:top-1 right-1 lg:right-8" src={urlFor(reviewHeroData.btnEditor.btnEditorIcon)} />}
            </p>
            <span
              className={`absolute top-0 left-0 right-0 bottom-0 ${
                isActive(reviewHeroData.btnEditor.btnEditorText)
                  ? "text-gray-900 bg-gradient-to-r from-yellow-500 to-yellow-300 transform skew-x-[-20deg] z-0"
                  : "absolute top-0 left-0 right-0 bottom-0 border-[1px] border-yellow-500 transform skew-x-[-20deg] z-0"
              }`}
            ></span>
          </button>
        </div>
        <section className="review-box-container flex items-start justify-between pt-20">
          <div className="md:w-1/2 w-full review-box">
            {pageData && filteredReviewsData
              .slice(startIndex, startIndex + reviewsPerPage)
              .map((review, index) => (
                <div key={review.id} className="">
                  <Link
                    to={`/${pageData.reviews ? pageData.reviews : 'reviews'}/${review.path}`}
                    className=" flex flex-col md:items-start items-center space-y-5 mb-20 pb-10 border-b-2 "
                  >
                    <img
                      src={urlFor(review.reviewCardImage)}
                      alt={review.authorName}
                      className="w-full rounded-2xl"
                    />
                    <div className="flex items-center space-x-4">
                      <p className="bg-amber-500 text-xl lg:text-[21px] leading-10 text-white font-normal text-center  px-4 rounded-sm">
                        {review.authorName}
                      </p>
                      <p className="text-base lg:text-[1.1vw] lg:leading-10 text-gray-700 ">{convertDateToPrintable(review.releaseTime)}</p>
                    </div>
                    <p className="text-xl md:text-3xl lg:text-4xl 2xl:text-[40px] 2xl:leading-[54px] text-center md:text-start text-gray-700 font-semibold">
                      {review.reviewDetailtextContent[0].header}
                    </p>
                    <p className="text-base lg:text-[22px] lg:leading-8 xl:leading-10 2xl:leading-[46px] text-gray-900 font-normal text-center md:text-start">
                      {review.reviewDetailtextContent[0].description[0]}
                    </p>
                  </Link>
                </div>
              ))}
          </div>
          <div className="review-box-link flex-col w-2/5 md:block hidden">
            {pageData && filteredReviewsData
              .slice(startIndex, startIndex + reviewsPerPageLink)
              .map((review, index) => (
                <div key={review.id}>
                  <Link
                    to={`/${pageData.reviews ? pageData.reviews : 'reviews'}/${review.path}`}
                    className="w-full flex items-center space-x-4 mb-24"
                  >
                    <img
                      src={urlFor(review.reviewCardImage)}
                      alt={review.authorName}
                      className="w-[15vw] h-[20vh] rounded-2xl"
                    />
                    <div className="flex flex-col items-start space-y-4">
                      <div className="flex items-center space-x-4">
                        <p className="text-[1vw] font-normal text-white text-center py-1 px-4 rounded-sm" style={{ backgroundColor: getRandomColor(customColors) }}> 
                          {review.authorName}
                        </p>
                        <p className="text-[.8vw] text-black">
                        {convertDateToPrintable(review.releaseTime)}
                        </p>
                      </div>
                      <p className="text-xl text-gray-800 font-semibold">
                        {review.reviewDetailtextContent[0].header}
                      </p>
                    </div>
                  </Link>
                </div>
              ))}
          </div>
        </section>
        <div className="flex justify-center space-x-8  mt-0">
          <button
            onClick={handlePrevPage}
            disabled={startIndex === 0}
            className="w-[40vw] lg:w-[12vw] bg-gradient-to-r from-yellow-500 to-yellow-300 text-gray-700 font-medium lg:text-2xl py-2 px-4 rounded-md"
          >
            {reviewHeroData.btnPrevious}
          </button>
          <button
            onClick={handleNextPage}
            disabled={startIndex + reviewsPerPage >= filteredReviewsData.length}
            className="w-[40vw] lg:w-[12vw] bg-gradient-to-r from-yellow-500 to-yellow-300 text-gray-700 font-medium lg:text-2xl  py-2 px-4 rounded-md"
          >
            {reviewHeroData.btnNext}
          </button>
        </div>
      </div>
      {/* <ReviewComment /> */}
    </div>
    <HomeConsultant />
    {/* <HomeSubscribe /> */}
    <Footer />
    </>
  );
}

export default ReviewPage;
