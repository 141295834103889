import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import client from "../../../sanityCli"; // Import your Sanity client
import imageUrlBuilder from "@sanity/image-url";

const builder = imageUrlBuilder(client);

function urlFor(source) {
  return builder.image(source);
}

function LatestBlog() {
  const [latestBlogData, setLatestBlogData] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    // Fetch data from Sanity
    client
      .fetch('*[_type == "latestBlog"][0]')
      .then((data) => setLatestBlogData(data))
      .catch(console.error);
  }, []);
  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0
        ? latestBlogData.virtualCrouselBox.length - 1
        : prevIndex - 1
    );
  };

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === latestBlogData.virtualCrouselBox.length - 1
        ? 0
        : prevIndex + 1
    );
  };

  const handleDotClick = (index) => {
    setCurrentIndex(index);
  };

  if (!latestBlogData) {
    return <div></div>;
  }

  const {
    title,
    subTitle,
    description,
    virtualBox,
    smallBox_1,
    smallBox_2,
    virtualCrouselBox,
  } = latestBlogData;

  return (
    <div className="latest-blog my-[10%] mt-[25%]  lg:my-10">
      <div className="latest-blog-heading flex flex-col items-center justify-center lg:gap-8 gap-4">
        <h2 className="text-yellow-500 uppercase text-xl font-semibold">
          {title}
        </h2>
        <h1 className="text-center font-bold text-[5vw] md:text-4xl lg:text-4xl 2xl:text-6xl text-gray-800 Montserrat uppercase tracking-wider">
          {subTitle}
        </h1>
        <p className="text-center font-normal text-[3.2vw] px-[5vw] lg:px-[12vw] xl:px-[15vw] md:text-xl lg:text-2xl xl:text-[1.8vw] xl:leading-[48px] text-gray-700 Poppins leading-relaxed tracking-wide">
          {description}
        </p>
      </div>
      <div className="virtual-reality-container h-[360px] md:h-[80vw] lg:h-[35vw] m-[5%] flex items-center gap-4 lg:p-12 md:m-4 lg:m-20 bg-yellow-500 rounded-lg relative">
        <div className="left-image-container w-1/2 absolute bottom-0 left-1 md:block hidden">
          <img
            className="w-4/5"
            src={urlFor(virtualBox.virtualBoxImage).url()}
            alt="Virtual Box"
          />
        </div>
        <div className="lg:w-1/2 w-full absolute lg:right-16 right-0 flex flex-col md:items-start items-center space-y-8 lg:space-y-4 overflow-hidden">
          <h1 className="text-gray-800 font-semibold text-[5.8vw] pr-[5vw] xl:pr-[10vw] md:px-0 md:text-4xl lg:text-4xl xl:text-6xl text-center md:text-start font-montserrat uppercase tracking-tight">
            {virtualBox.virtualBoxTitle.split("").map((letter, index) => (
              <span
                key={index}
                style={{
                  color:
                    index >= 12 && index <= 19
                      ? window.innerWidth > 768
                        ? "violet"
                        : "yellow"
                      : "inherit", // Change color based on screen size
                }}
              >
                {letter}
              </span>
            ))}
          </h1>
          <p className="text-gray-800 font-normal text-[4vw] lg:text-[20px] xl:text-[24px] leading-snug tracking-wide font-poppins xl:pr-20 text-center lg:text-start px-4 md:px-0">
            {virtualBox.virtualBoxSubTitle}
          </p>
          <button className="bg-gray-800 text-white py-2 px-4 rounded-lg text-[3vw] lg:text-xl">
            <Link to={virtualBox.buttonLink}>
              {" "}
              {virtualBox.virtualBoxButttonText}{" "}
            </Link>
          </button>
        </div>
      </div>
      <div className="latest-blog-small-box flex items-start gap-4 m-4 xl:mx-20">
        <div className="w-1/4 relative md:block hidden ">
          <img
            className="w-full lg:h-[30vw] h-[45vh] rounded-md"
            src={urlFor(smallBox_1.boxImage)}
            alt="Small Box 1"
          />
          <div className="flex flex-col gap-2  absolute bottom-2 left-2 right-2 rounded-lg bg-white bg-opacity-50 backdrop-[3px] border border-gray-300 p-1">
            <h1 className="text-gray-800 text-lg lg:text-xl text-start">
              {smallBox_1.title}
            </h1>
            <p className="text-start text-sm lg:text-lg text-gray-800">
              {smallBox_1.subTitle.slice(0, 60)}...
            </p>
          </div>
        </div>
        <div className="w-1/4 relative md:block hidden">
          <img
            className="w-full lg:h-[30vw] h-[45vh] rounded-md"
            src={urlFor(smallBox_2.boxImage)}
            alt="Small Box 2"
          />
          <div className="flex flex-col gap-2  absolute bottom-2 left-2 right-2 rounded-lg bg-white bg-opacity-50 backdrop-[3px] border border-gray-300 p-1">
            <h1 className="text-gray-800 text-lg lg:text-xl text-start">
              {smallBox_2.title}
            </h1>
            <p className="text-start text-sm lg:text-lg text-gray-800">
              {smallBox_2.subTitle.slice(0, 60)}...
            </p>
          </div>
        </div>
        <div className="latest-blog-crousel-card md:w-2/4 w-full relative overflow-hidden rounded-lg">
          <button
            className="absolute md:left-5 left-0 md:top-[180px] top-[160px] transform -translate-y-1/2 z-10 bg-slate-100 rounded-lg text-center text-[5vw] font-semibold lg:text-xl w-[8vw] h-[8vw] md:w-10 md:h-10 pr-3"
            onClick={prevSlide}
          >
            〈
          </button>
          <button
            className="absolute md:right-5 right-0 md:top-[180px] top-[160px] transform -translate-y-1/2 z-10 bg-slate-100 rounded-lg text-center text-[5vw] font-semibold lg:text-xl w-[8vw] h-[8vw] md:w-10 md:h-10 pl-3"
            onClick={nextSlide}
          >
            〉
          </button>
          <div
            className="moving-container flex"
            style={{
              transition: "transform 0.5s ease",
              transform: `translateX(-${currentIndex * 100}%)`,
            }}
          >
            {/* Render carousel items dynamically */}
            {virtualCrouselBox.map((item) => (
              <div
                key={item.id}
                className="carousel-item flex-shrink-0 w-full relative"
              >
                <img
                  className="w-full lg:h-[30vw] h-[45vh]  rounded-md bg-opacity-5"
                  src={urlFor(item.image)}
                  alt={item.title}
                />
                <div className="w-[96%] flex flex-col gap-2 absolute bottom-2 lg:left-3 left-2  rounded-lg bg-slate-50 bg-opacity-50 backdrop-[3px] border border-gray-300 px-2 py-4">
                  <h1 className="text-gray-800 text-lg lg:text-xl text-start">
                    {smallBox_1.title}
                  </h1>
                  <p className="text-start text-sm lg:text-lg text-gray-800">
                    {smallBox_1.subTitle.slice(0, 60)}...
                  </p>
                </div>
                <div className="absolute bottom-4 right-5 flex space-x-2">
                  {[...Array(virtualCrouselBox.length)].map((_, dotIndex) => (
                    <div
                      key={dotIndex}
                      className={`w-2 h-2 rounded bg-slate-100 ${
                        dotIndex === currentIndex
                          ? "bg-yellow-300"
                          : "bg-slate-100"
                      } ${dotIndex === currentIndex ? "w-4 h-2" : ""}`}
                      onClick={() => handleDotClick(dotIndex)}
                    ></div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LatestBlog;

// import React, { useState, useEffect } from "react";
// import { crouselCards } from "../../utils/assets/image";

// function LatestBlog() {
//   const dummyData = [
//     {
//       id: 1,
//       imageSrc: crouselCards[4],
//       title: "First Blog Post",
//       content:
//         "Content of the first blog post. Many developers believe multiple monitors improve productivity. Studies have proven it, right? Well, keep in mind, many of those studies are commissioned from monitor manufacturers.",
//     },

//     {
//       id: 2,
//       imageSrc: crouselCards[1],
//       title: "Second Blog Post",
//       content:
//         "Content of the first blog post. Many developers believe multiple monitors improve productivity. Studies have proven it, right? Well, keep in mind, many of those studies are commissioned from monitor manufacturers.",
//     },
//     {
//       id: 3,
//       imageSrc: crouselCards[3],
//       title: "Third Blog Post",
//       content:
//         "Content of the first blog post. Many developers believe multiple monitors improve productivity. Studies have proven it, right? Well, keep in mind, many of those studies are commissioned from monitor manufacturers.",
//     },
//     {
//       id: 4,
//       imageSrc: crouselCards[4],
//       title: "Fourth Blog Post",
//       content:
//         "Content of the first blog post. Many developers believe multiple monitors improve productivity. Studies have proven it, right? Well, keep in mind, many of those studies are commissioned from monitor manufacturers.",
//     },
//     {
//       id: 5,
//       imageSrc: crouselCards[2],
//       title: "Fourth Blog Post",
//       content:
//         "Content of the first blog post. Many developers believe multiple monitors improve productivity. Studies have proven it, right? Well, keep in mind, many of those studies are commissioned from monitor manufacturers.",
//     },
//     // Add more dummy data objects as needed
//   ];

//   // State to track the current index of the carousel
//   const [currentIndex, setCurrentIndex] = useState(0);

//   // Function to handle automatic sliding
//   useEffect(() => {
//     const interval = setInterval(() => {
//       setCurrentIndex((prevIndex) =>
//         prevIndex === dummyData.length - 1 ? 0 : prevIndex + 1
//       );
//     }, 8000); // Change slide duration as needed
//     return () => clearInterval(interval);
//   }, [dummyData.length]);

//   // Function to handle manual sliding to the previous item
//   const prevSlide = () => {
//     setCurrentIndex((prevIndex) =>
//       prevIndex === 0 ? dummyData.length - 1 : prevIndex - 1
//     );
//   };

//   // Function to handle manual sliding to the next item
//   const nextSlide = () => {
//     setCurrentIndex((prevIndex) =>
//       prevIndex === dummyData.length - 1 ? 0 : prevIndex + 1
//     );
//   };

//   const handleDotClick = (index) => {
//     setCurrentIndex(index);
//   };

//   return (
//     <div className="latest-blog my-12">
//       <div className="latest-blog-heading flex flex-col items-center justify-center gap-8">
//         <h2 className="text-yellow-500 uppercase text-xl font-semibold">
//           {title}
//         </h2>
//         <h1 className="text-center font-bold text-4xl md:text-4xl lg:text-4xl 2xl:text-6xl text-black Montserrat uppercase tracking-wider">
//           {subTitle}
//         </h1>
//         <p className="text-center font-normal text-lg md:text-xl lg:text-3xl text-gray-700 Poppins leading-relaxed tracking-wide">
//            {description}
//         </p>
//       </div>
//       <div className="virtual-reality-container h-[440px] lg:h-[400px] xl:h-[480px]  w-[90%] m-[5%] lg:ml-[5%] flex items-center gap-4 lg:p-12 md:m-4 xl:m-20 bg-yellow-500 rounded-lg relative">
//         <div className="left-image-container w-1/2 absolute bottom-0 left-[-12px]  md:block hidden">
//           <img
//             className="w-full"

//             src={virtuaBoxImage}
//           />
//         </div>
//         <div className="lg:w-1/2 w-full absolute lg:right-16 right-0 flex flex-col md:items-start items-center gap-6 xl:gap-8">
//           <h1 className="text-gray-700 font-semibold text-2xl px-20 md:px-0 md:text-4xl lg:text-6xl xl:text-6xl text-center md:text-start font-montserrat uppercase leading-tight tracking-tight">
//             {virtualBoxTitle}
//             {/* <span className="text-transparent bg-gradient-to-r from-yellow-200 to-yellow-100  md:bg-gradient-to-r md:from-violet-600 md:to-violet-400 bg-clip-text">
//               Virtual{" "}
//             </span>{" "}
//             Reality Gaming */}
//           </h1>
//           <p className="text-gray-700 font-medium text-lg leading-snug tracking-wide font-poppins xl:pr-20 text-center lg:text-start px-12 md:px-0">
//              {virtualBoxSubTitle}
//           </p>
//           <button className="bg-gray-800 text-white p-4 rounded-lg text-xl">
//             {virtualBoxButtonText}
//           </button>
//         </div>
//       </div>
//       <div className="latest-blog-small-box flex items-start gap-4 m-4 xl:mx-20">
//         <div className="w-1/4 relative md:block hidden ">
//           <img
//             className="w-full h-96 rounded-md"
//             src={smallBox_1.boxImage}
//           />
//           <div className="flex flex-col gap-2  absolute bottom-2 left-2 right-2 rounded-lg bg-white bg-opacity-50 backdrop-[3px] border border-gray-300 p-1">
//             <h1 className="text-gray-800 text-2xl text-center">
//             {smallBox_1.title}
//             </h1>
//             <p className="text-center text-gray-800">
//             {smallBox_1.subTitle}
//             </p>
//           </div>
//         </div>
//         <div className="w-1/4 relative md:block hidden">
//           <img
//             className="w-full h-96 rounded-md"
//             src={smallBox_2.boxImage}
//           />
//           <div className="flex flex-col gap-2  absolute bottom-2 left-2 right-2 rounded-lg bg-white bg-opacity-50 backdrop-[3px] border border-gray-300 p-1">
//             <h1 className="text-gray-800 text-2xl text-center">
//             {smallBox_2.title}
//             </h1>
//             <p className="text-center text-gray-800">
//             {smallBox_2.subTitle}

//             </p>
//           </div>
//         </div>
//         <div className="latest-blog-crousel-card md:w-2/4 w-full relative overflow-hidden rounded-lg">
//         <button
//           className="absolute md:left-5 left-0 md:top-[180px] top-[160px] transform -translate-y-1/2 z-10 bg-slate-100 rounded-lg text-center text-xl w-12 h-12 pr-3"
//           onClick={prevSlide}
//         >
//           〈
//         </button>
//         <button
//           className="absolute md:right-5 right-0 md:top-[180px] top-[160px] transform -translate-y-1/2 z-10 bg-slate-100 rounded-lg text-center text-xl w-12 h-12 pl-3"
//           onClick={nextSlide}
//         >
//           〉
//         </button>
//         <div
//           className="moving-container flex"
//           style={{
//             transition: "transform 0.5s ease",
//             transform: `translateX(-${currentIndex * 100}%)`,
//           }}
//         >
//           {/* Render carousel items dynamically */}
//           {dummyData.map((item) => (
//             <div key={item.id} className="carousel-item flex-shrink-0 w-full relative">
//               <img
//                 className="w-full h-96 rounded-md bg-opacity-5"
//                 src={item.imageSrc}
//                 alt={item.title}
//               />
//               <div className="w-[96%] flex flex-col gap-2 absolute bottom-2 left-3 rounded-lg bg-slate-50 bg-opacity-50 backdrop-[3px] border border-gray-300 px-2 py-4">
//                 <h1 className="text-gray-800 text-2xl text-center">
//                   {item.title}
//                 </h1>
//                 <p className="text-center text-gray-800">{item.content}</p>
//               </div>
//              <div className="absolute bottom-4 right-5 flex space-x-2">
//                 {[...Array(dummyData.length)].map((_, dotIndex) => (
//                   <div
//                     key={dotIndex}
//                     className={`w-2 h-2 rounded bg-slate-100 ${
//                       dotIndex === currentIndex ? "bg-yellow-300" : "bg-slate-100"
//                     } ${dotIndex === currentIndex ? "w-4 h-2" : ""}`}
//                     onClick={() =>handleDotClick(dotIndex)}
//                   ></div>
//                 ))}
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//       </div>
//     </div>
//   );
// }

// export default LatestBlog;
