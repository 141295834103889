import React, { useEffect, useState } from "react";
import imageUrlBuilder from "@sanity/image-url";
import client from "../../sanityCli";
import Footer from "../Home/homeSec9/Footer";
import Navbar from "../navbar/Navbar";

const builder = imageUrlBuilder(client);

function urlFor(source) {
  return builder.image(source);
}

export default function CookiesPolicy() {
  const [cookiesPolicy, setCookiesPolicy] = useState(null);

  useEffect(() => {
    let passedValue = localStorage.getItem("myData");
    if (passedValue !== "true") {
      window.location.reload();
      localStorage.setItem("myData", "true");
    }
    const fetchData = async () => {
      try {
        const result = await client.fetch('*[_type == "CookiesPolicy"][0]');
        setCookiesPolicy(result);
      } catch (error) {
        console.error("Error fetching data from Sanity:", error);
      }
    };

    fetchData();
  }, []);

  if (!cookiesPolicy) {
    return <div></div>;
  }

  const {
    privacyPromiss,
    privacyPolicyTitle,
    privacyPolicyDescription,
    effectiveData,
    privacyPolicyWebsite,
    generalProvisionTitle,
    generalProvisionDescription,
    otherProvisionTitle,
    otherProvisionDescription,
    images: { firstImg, secondImg, thirdImg },
  } = cookiesPolicy;

  return (
    <>
    <Navbar />
    <div className=" w-full lg:px-60 px-8 pt-12 pb-20 flex items-start lg:gap-40">
      <div className="md:w-3/5 w-full">
        <div className="flex flex-col gap-6 md:my-16 my-4">
          <p className="capitalize text-base text-gray-800">{privacyPromiss}</p>
          <p className="text-4xl text-black font-medium">
            {privacyPolicyTitle}
          </p>
          <p className="text-base text-gray-800">{privacyPolicyDescription}</p>
          <p className="flex items-center gap-8">
            <span className="text-sm capitalize">{effectiveData}</span>
            <span className="text-sm capitalize">{privacyPolicyWebsite}</span>
          </p>
        </div>
        <div className="flex flex-col gap-4">
          <p className="text-3xl text-gray-800 capitalize">
            {generalProvisionTitle}
          </p>
          {generalProvisionDescription.map((desc, index) => (
            <p className="text-sm text-gray-700" key={index}>
              {desc}
            </p>
          ))}
        </div>
        <div className="flex flex-col gap-4 mt-20">
          <p className="text-3xl text-gray-800 capitalize">
            {otherProvisionTitle}
          </p>
          {otherProvisionDescription.map((desc, index) => (
            <p className="text-sm text-gray-700" key={index}>
              {desc}
            </p>
          ))}
        </div>
      </div>
      <div className="md:w-1/5 md:block hidden">
        <img src={urlFor(firstImg)} alt="Pen" className="ml-6" />
        <img src={urlFor(secondImg)} alt="Line" className="w-40 -mt-2" />
        <img src={urlFor(thirdImg)} alt="Home" className="-mt-20 w-96" />
      </div>
    </div>
    <Footer />
    </>
  );
}
