import React, { useEffect, useState } from "react";
import "./HomeConsultant.css";
import client from "../../../sanityCli";
import imageUrlBuilder from "@sanity/image-url";
import { v4 as uuidv4 } from "uuid";
// import {normalizedBlock} from "uuidv4";

const builder = imageUrlBuilder(client);

function urlFor(source) {
  return builder.image(source);
}

export default function HomeConsultant() {
  const [contentData, setContentData] = useState({});

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    services: [],
    message: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await client.fetch('*[_type == "contactForm"][0]');
        setContentData(data);
      } catch (error) {
        console.error("Error fetching data from Sanity:", error);
      }
    };

    // console.log("Updated form data:", formData);
    fetchData();
  }, [formData]);

  const postData = async (formData) => {
    fetchFormData().then((data) => {
      console.log("data", data);
      if (data) {
        const newSubmissionData = {
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          phone: formData.phone,
          services: formData.services,
          message: formData.message,
          _key: uuidv4(),
        };
        appendSubmission(data._id, newSubmissionData);
      }
    });
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setFormData((prevState) => ({
        ...prevState,
        services: checked
          ? [...prevState.services, value]
          : prevState.services.filter((service) => service !== value),
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const appendSubmission = async (documentId, newSubmissionData) => {
    // newSubmissionData._key = uuidv4();
    try {
      await client
        .patch(documentId) // Use the document ID
        .setIfMissing({ submissions: [] }) // Initializes submissions array if it doesn't exist
        .insert("after", "submissions[-1]", [newSubmissionData]) // Appends the new submission
        .commit();

      // console.log("Form submission updated and published successfully.");
    } catch (error) {
      console.error("Error updating and publishing form submission:", error);
    }
  };

  const fetchFormData = async () => {
    try {
      const data = await client.fetch('*[_type == "contactForm"][0]');
      setContentData(data);
      return data; // Return data to potentially use its ID
    } catch (error) {
      console.error("Error fetching data from Sanity:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !formData.firstName ||
      !formData.lastName ||
      !formData.email ||
      !formData.phone ||
      formData.services.length === 0 ||
      !formData.message
    ) {
      alert(
        "Please fill in all required fields and select at least one service."
      );
      return;
    }

    try {
      const formSubmission = {
        _type: "contactFormSubmission",
        ...formData,
      };

      await postData(formSubmission);
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        services: [],
        message: "",
      });
      // Reset checkboxes in UI
      const checkboxes = document.querySelectorAll('input[type="checkbox"]');
      checkboxes.forEach((checkbox) => {
        checkbox.checked = false;
      });
    } catch (error) {
      console.error("Error submitting form data:", error);
      alert(
        "An error occurred while submitting the form. Please try again later."
      );
    }
  };

  return (
    <div
      className="w-full lg:flex flex-col items-center justify-center py-20 pt-4 lg:pt-20 gap-10"
      id="contact"
    >
      <div className="contact-form w-[94%] lg:w-[90%] flex flex-col lg:flex-row lg:items-center justify-center gap-5 px-[2%] ml-[3%] lg:ml-[5%]">
        <div id='black-box' className="contact-address relative w-full lg:w-2/5 h-[50vh] lg:h-[50vw]  flex flex-col items-start justify-start gap-4 lg:gap-8 text-white p-8 pb-40 rounded-xl">
          {contentData.bgImage2 && (
            <img
              src={urlFor(contentData.bgImage2)}
              alt="/"
              className="absolute right-0 bottom-0 rounded-lg"
            />
          )}
          {contentData.bgImage3 && (
            <img
              src={urlFor(contentData.bgImage3)}
              alt="/"
              className="absolute right-0 bottom-0 rounded-lg"
            />
          )}
          <h1 className="text-start font-semibold text-[5vw] leading-[8vw] md:text-2xl lg:text-3xl">
            {contentData.contactTitle}
          </h1>
          <p className="text-start text-[4vw] md:lg lg:text-xl font-normal">
            {contentData.contactDescription}
          </p>
          <div className="flex flex-col items-start gap-5">
            <a className="flex items-center gap-4" href={contentData.phoneUrl}>
              {contentData.phoneIcon && (
                <img
                  src={urlFor(contentData.phoneIcon)}
                  alt="/"
                  className="w-7 lg:w-10"
                />
              )}
              <p className=" text-sm lg:text-xl">{contentData.phnNumber}</p>
            </a>

            <a className="flex items-center gap-4" href={contentData.emailUrl}>
              {contentData.emailIcon && (
                <img
                  src={urlFor(contentData.emailIcon)}
                  alt="/"
                  className="w-7 lg:w-10"
                />
              )}
              <p className="text-sm lg:text-xl">{contentData.email}</p>
            </a>

            <a className="flex items-center gap-4" href={contentData.webUrl}>
              {contentData.webIcon && (
                <img
                  src={urlFor(contentData.webIcon)}
                  alt="/"
                  className="w-7 lg:w-10"
                />
              )}
              <p className="text-sm lg:text-xl">{contentData.webAddress}</p>
            </a>

            <a
              className="flex items-center gap-4"
              href={contentData.locationUrl}
            >
              {contentData.locationIcon && (
                <img
                  src={urlFor(contentData.locationIcon)}
                  alt="/"
                  className="w-7 lg:w-10"
                />
              )}
              <div className="flex flex-col gap-2">
                <p className="text-sm lg:text-xl">{contentData.location}</p>
                <p className="text-sm lg:text-xl">{contentData.location2}</p>
                <p className="text-sm lg:text-xl">{contentData.location3}</p>
              </div>
            </a>
          </div>
        </div>
        <form className="w-[100%] lg:w-3/5" onSubmit={handleSubmit}>
          <div className="mt-4 flex items-center gap-2 lg:justify-between lg:px-16">
            <span className="flex flex-col items-start w-2/5">
              <label htmlFor="first-name" className="text-base lg:text-2xl font-medium">
                {contentData.firstNameLabel}
              </label>
              <input
                type="text"
                id="first-name"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                // placeholder="First Name"
                required
                className="input-box w-18 border-b border-gray-500 p-2 text-sm lg:text-base bg-yellow-50"
              />
            </span>
            <span className="flex flex-col items-start gap-2 w-1/2">
              <label htmlFor="last-name" className="text-base lg:text-2xl font-medium">
                {contentData.lastNameLabel}
              </label>
              <input
                type="text"
                id="lastName"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                // placeholder="Last Name"
                required
                className="input-box  border-b border-gray-500 p-1 text-sm lg:text-base bg-yellow-50"
              />
            </span>
          </div>
          <div className="lg:px-16 py-12 flex items-center lg:justify-between">
            <span className="flex flex-col items-start gap-2 w-[45vw]">
              <label htmlFor="email" className="text-base lg:text-2xl font-medium">
                {contentData.mailLabel}
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                // placeholder="Email"
                required
                className="input-box w-18 border-b border-gray-500 p-2 text-sm lg:text-base bg-yellow-50"
              />
            </span>
            <span className="flex flex-col items-start gap-2 w-[45vw]">
              <label htmlFor="phone" className="text-base lg:text-2xl font-medium">
                {contentData.phoneLabel}
              </label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                // placeholder="Phone"
                required
                className="input-box mr-4 border-b-[1px] border-gray-500 p-2 text-sm lg:text-base bg-yellow-50"
              />
            </span>
          </div>

          <h1 className="lg:px-16 lg:py-12 text-lg md:text-xl lg:text-2xl font-normal">
            {contentData.serviceTitle}
          </h1>
          <div className="services-check flex flex-wrap items-center justify-start md:gap-8 md:px-16 mt-2 lg:mt-0">
            {contentData.serviceLabels &&
              contentData.serviceLabels.map((label, index) => (
                <span className="flex items-center gap-4 m-2 lg:m-0" key={index}>
                  <input
                    type="checkbox"
                    name="services"
                    value={label}
                    onChange={handleChange}
                    className="rounded-full"
                  />
                  <label className="text-base lg:text-base font-medium">
                    {label}
                  </label>
                </span>
              ))}
          </div>
          <div className="lg:px-16 py-4 lg:py-8 flex flex-col gap-8">
            <label
              htmlFor="message"
              className="text-base lg:text-xl text-start font-medium"
            >
              {contentData.messageLabel}
            </label>
            <input
              type="text"
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              className="w-full border-b border-gray-500 bg-yellow-50 p-2 text-sm lg:text-base"
              placeholder="Write Your Message Here"
              required
            />
          </div>
          <button
            type="submit"
            className="lg:mx-12 mx-[35%] my-6 px-4 py-2 text-xl font-medium bg-gradient-to-r from-yellow-500 to-yellow-300 rounded-lg"
          >
            {contentData.submit}
          </button>
        </form>
      </div>
    </div>
  );
}
